<template>
  <v-container fluid>
      <v-row class="d-flex justify-end">
        <v-col cols="6">
          <h1 class="display-1 font-weight-bold mx-1 mb-3">
            Internal Orders
          </h1>
        </v-col>
        <v-col cols="3">
        <v-autocomplete
          v-model="accountNumber"
          :items="customers"
          outlined
          clearable
          clear-icon="mdi-close-box"
          dense
          item-text="customer"
          item-value="accountNumber"
          label="Customer"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content v-text="`${data.item.customer} (${data.item.count})`"></v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        </v-col>
        <v-col cols="3">
        <v-autocomplete
          v-model="status"
          :items="statuses"
          outlined
          clearable
          dense
          label="Status"
        ></v-autocomplete>
        </v-col>
      </v-row>
    <v-data-table
    dense
      :items="filteredOrders"
      item-key="_id"
      :headers="headers"
      :items-per-page="20"
      :sort-by="['createdAt']"
      :sort-desc="[true]"
      :loading="loadingData"
      loading-text="Loading... Please wait"
      :footer-props="{
        itemsPerPageOptions: [10, 20, 50]
      }"
      >
      <template v-slot:[`item.download`]="{ item }">
        <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="mr-4" color="success">
                mdi-download
              </v-icon>
            </template>
            <v-list class="pt-0">
              <v-list-item @click="download(item.orderNumber)">
                <v-list-item-title>Customer Order</v-list-item-title>
              </v-list-item>
              <v-list-item disabled @click="download(item.orderNumber)">
                <v-list-item-title>UV Worksheet</v-list-item-title>
              </v-list-item>
              <v-list-item disabled @click="download(item.orderNumber)">
                <v-list-item-title>Giclees Worksheet</v-list-item-title>
              </v-list-item>
              <v-list-item disabled @click="download(item.orderNumber)">
                <v-list-item-title>Products Worksheet</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
      </template>
      <!-- <template v-slot:[`item.changeStatus`]="{ item }">
        <change-status :loading="loadingData" :statuses="orderStatuses" :orderDelivery="item.delivery" :orderStatus="item.status" :orderId="item.id" @changeStatus="updateStatus" />
      </template> -->
      <template v-slot:[`item.total`]="{ item }">
        {{ item.orderItems.length }} (£{{item.orderTotal.toFixed(2)}})
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ new Date(item.createdAt).toLocaleDateString()}}
      </template>
      <template v-slot:[`item.accountNumber`]="{ item }">
        <a v-if="item.user" target="_blank" v-bind:href="'https://go.xero.com/Contacts/View/' + item.user.contactID">{{item.accountNumber}}</a>
      </template>
      <template v-slot:[`item.xeroInvoiceId`]="{ item }">
        <a v-if="item.xeroInvoiceId" target="_blank" v-bind:href="'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' + item.xeroInvoiceId">{{item.xeroInvoiceNumber}}</a>
      </template>
    </v-data-table>
    <confirm-dialog ref="confirmDialog" />
  </v-container>
</template>lank'

<script>
import OrderApi from '@/api/order'
import ChangeStatus from './changeStatus.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import AccountingApi from '@/api/accounting'
import _ from 'lodash'

export default {
  props: {
  },
  components: {
    ChangeStatus,
    ConfirmDialog
  },
  data () {
    return {
      loadingData: true,
      accountNumber: null,
      customers: [],
      orders: [],
      status: null,
      statuses: ['ACCEPTED', 'COMPLETED', 'PENDING', 'VOID', 'DISPATCHED', 'READY FOR COLLECTION'],
      orderStatuses: {},
      headers: [
        {
          text: 'Customer',
          align: 'start',
          value: 'user.name'
        },
        {
          text: 'Xero a/c',
          align: 'end',
          value: 'accountNumber',
          width: 100
        },
        {
          text: 'INV',
          align: 'center',
          value: 'xeroInvoiceId'
        },
        {
          text: 'No',
          align: 'center',
          value: 'orderNumber'
        },
        {
          text: 'Date',
          value: 'createdAt'
        },
        {
          text: 'Items',
          value: 'total',
          width: 150
        },
        {
          text: 'Status',
          align: 'start',
          value: 'status'
        },
        {
          text: '',
          align: 'end',
          value: 'download'
        }
      ]
    }
  },
  async created () {
    OrderApi.getOrderStatuses().then((results) => {
      this.orderStatuses = results.data
    })
    OrderApi.countUserOrders().then((results) => {
      this.customers = results.data
    })
    OrderApi.getInternalOrders().then((results) => {
      this.orders = results.data
      this.loadingData = false
    })
  },
  computed: {
    filter () {
      const obj = {}
      if (this.accountNumber) obj.accountNumber = this.accountNumber
      if (this.status) obj.status = this.status
      return obj
    },
    filteredOrders: function () { return _.filter(this.orders, this.filter) }
  },
  watch: {},
  methods: {
    download (orderNumber) {
      OrderApi.getPDF(orderNumber).then(() => {
        console.log('pdf download')
      })
    },
    async updateStatus (data) {
      let xeroAuthenticated = false
      await AccountingApi.hasXeroConnection().then(response => {
        xeroAuthenticated = response.data
      })
      if (xeroAuthenticated) {
        this.loadingData = true
        await OrderApi.updateOrderStatus(data.id, data.status)
        await OrderApi.getOrders().then((results) => {
          this.loadingData = false
          this.orders = results.data
        })
      } else {
        this.$router.go()
      }
    }
  }
}
</script>
