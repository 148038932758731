<template>
  <v-dialog v-model="value" persistent scrollable max-width="70vw">
  <v-card v-if="promotion">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Email this Promotion</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        Send to the following email list
      </v-card-title>
      <v-card-text>
      <v-select
        v-model="mailingList"
        :items="mailingLists"
        :item-text="getMailingListText"
        return-object
        outlined
        dense
        block
        hide-details
        label="Mailing List"
        class="mt-2"
      >
      </v-select>
      </v-card-text>
    <v-card-actions>
      <v-btn color="success" :disabled="!mailingList" class="mr-4" @click.native="emailPromotion()">
        Send
      </v-btn>
    </v-card-actions>
    <confirm-dialog ref="confirmDialog" />
  </v-card>
  </v-dialog>
</template>

<script>
import MailingListApi from '@/api/mailingList'
import MailingList from '@/constants/mailingLists'
import PromotionApi from "@/api/promotion"
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  props: {
    value: Boolean,
    promotion: Object,
  },
  components: {
    ConfirmDialog
  },
  data() {
    return {
      mailingList: null,
      mailingLists: [],
    }
  },
  async created() {
    MailingListApi.loadMailingLists().then( resp =>{
      const _mailingLists = Object.keys(resp.data)
      this.mailingLists = _mailingLists.map( key => ({key: key, name: MailingList[key]?.name, contacts: resp.data[key]}))
    })
  },
  mounted: function () {},
  computed: {},
  watch: {},
  methods: {
    close() {
      this.$emit('close')
    },
    getMailingListText: (item) => `${item.name} (${item.contacts.length})`,
    async emailPromotion() {
      if (
        await this.$refs.confirmDialog.open(
          `This will email the following users`,
          this.mailingList.contacts.map(item => `${item.emailAddress} ${item.name}`)
        )
      ) {
        PromotionApi.emailPromotion({ promotion: this.promotion, template: 'xmasMessage2024', mailingList: this.mailingList.key }).then((result) => {
        console.log(result.data)
        this.close()
        }).catch(
          err => {
            console.log('err', err)
            this.loadingData = false
          }
        )
      }
    },
  }
}
</script>
