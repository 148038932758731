export default {
  CUSTOM_ARTIST : {
    name: "Custom artists",
    description: "Artists and other customers who commission products and pronts from their own artworks"
  },
  PUBLISHED_ARTIST: {
    name: "Published artists",
    description: "Artists who are featured in the published range"
  },
  RETAIL: {
    name: "All retail customers",
    description: "Retail outlets who order products from the published range"
  },
  SPECIAL: {
    name: "Special mailing list",
    description: "Reserved mailing list"
  },
  ACTIVE: {
    name: "All active Xero users",
    description: "All active Xero contacts, both suppliers and customers"
  },
  ADMIN_USER: {
    name: "Admin users for testing",
    description: "Admin users with category ADMIN_USER"
  },
  ALL_CUSTOMERS: {
    name: "All customers",
    description: "Artists (custom and published) and retailers"
  }
}
