import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/admin/product`

const getProductBySKU = (args) => {
  return axios.post(api + '/getProductBySKU', args)
}
const saveProduct = (product) => {
  return axios.post(api, { product: { ...product } })
}
const updateProduct = (product) => {
  return axios.put(api, { product: { ...product } })
}
const setProductStatus = (product) => {
  return axios.post(api + '/setStatus', { product: { ...product } })
}
const setSelectedStatus = (products, status) => {
  return axios.post(api + '/setSelectedStatus', { products: [ ...products], status })
}
const loadAllProducts = (args = {filter: {}}) => {
  return axios.post(api + '/loadAllProducts', { ...args})
}
const loadProductSales = () => {
  return axios.get(api + '/loadProductSales')
}
const loadCustomerSales = () => {
  return axios.get(api + '/loadCustomerSales')
}
const loadArchivedProducts = () => {
  return axios.get(api + '/archivedProducts')
}
const loadProductsForXero = (args) => {
  return axios.post(api + '/loadProductsForXero', args)
}
const getCalendarPDF = (image) => {
  return axios.post(api + '/getCalendarPdf', { image }, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = 'Doricmor_Calendar.pdf'
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}
const getGreetingCardPDF = (image) => {
  return axios.post(api + '/getGreetingCardPDF', { image }, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = 'Doricmor_Card.pdf'
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}
const getCatalogPDF = (artistCodes) => {
  return axios.post(api + '/getCatalogPDF', artistCodes, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = 'Doricmor_Catalogue.pdf'
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}
export default {
  getProductBySKU,
  saveProduct,
  updateProduct,
  setProductStatus,
  setSelectedStatus,
  loadArchivedProducts,
  loadAllProducts,
  loadProductSales,
  loadCustomerSales,
  loadProductsForXero,
  getCalendarPDF,
  getCatalogPDF,
  getGreetingCardPDF
}
