<template>
  <v-card class="d-flex">
    <v-list dense>
      <v-list-item class="contact-address" v-for="(item, index) of fields" :key="index" v-html="item"></v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    contact: Object
  },
  data () {
    return {
      addressFields: [
        'fullName',
        'name',
        'addressLine1',
        'addressLine2',
        'addressLine3',
        'addressLine4',
        'city',
        'postalCode',
        'email'
      ]
    }
  },
  mounted: function () {
  },
  computed: {
    fields: function () {
    const arr = []
    const address = this.contact.addresses.find(item => item.addressType === 'POBOX')
    const contact = { ...this.contact, ...address }
    contact.fullName = `${this.contact.firstName ? this.contact.firstName + ' ' : ''}${this.contact.lastName || ''}`
    contact.email = `<a href="mailto://${this.contact.emailAddress}">${this.contact.emailAddress}</a>`
    for (const field of this.addressFields) {
      if (contact[field] && contact[field].length) { arr.push(contact[field]) }
    }
    return arr
  },
  },
  methods: {
  }
}
</script>
<style scoped>
.contact-address {
  min-height: 0 !important;
}
</style>
