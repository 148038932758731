<template>
  <div v-if="xeroCheck">
    <template v-if="!xeroAuthenticated">
      <authorise-xero />
    </template>
    <template v-if="xeroAuthenticated">
    <v-container fluid>
      <xero-list />
    </v-container>
    </template>
  </div>
  <v-main v-else app>
    <v-container fluid>
      <standard-list />
    </v-container>
  </v-main>
</template>

<script>
import XeroList from './xeroList'
import StandardList from './standardList'
import AuthoriseXero from '@/views/Admin/Xero/AuthoriseXero.vue'
import AccountingApi from '@/api/accounting'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    XeroList,
    StandardList,
    AuthoriseXero
  },
  data () {
    return {
      xeroCheck: false,
      xeroAuthenticated: false
    }
  },
  async created () {
    if (this.hasXeroAccess) {
      AccountingApi.hasXeroConnection().then(response => {
        this.xeroAuthenticated = response.data
        this.xeroCheck = true
      })
    }
  },
  computed: mapState({
    ...mapGetters([
      'hasXeroAccess'
    ])
  }),
  methods: {
  }
}
</script>

