<template>
  <v-main app>
    <v-navigation-drawer
      clipped
      app
      fixed
      :permanent="$vuetify.breakpoint.mdAndUp"
      style="z-index: 1"
    >
      <v-list>
        <v-list-item>
          <h3>Audiences</h3>
        </v-list-item>
        <v-list-item>
          <v-btn class="d-flex justify-start" width="100%" outlined color="primary" to="/admin/business/marketing/contacts">
            <v-icon class="mr-4">
              mdi-account-multiple
            </v-icon>
            Contacts
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="d-flex justify-start" width="100%" outlined color="primary" to="/admin/business/marketing/mailinglists">
            <v-icon class="mr-4">
              mdi-email-multiple
            </v-icon>
            Mailing Lists
          </v-btn>
        </v-list-item>
        <v-list-item>
          <h3>Promotions</h3>
        </v-list-item>
        <v-list-item>
          <v-btn class="d-flex justify-start" width="100%" outlined color="primary" to="/admin/business/marketing/artists">
            <v-icon class="mr-4">
              mdi-file-document-outline
            </v-icon>
            Promote Artist
          </v-btn>
        </v-list-item>
        <!-- <v-list-item>
          <v-btn class="d-flex justify-start" width="100%" outlined color="primary" to="/admin/business/marketing/products">
            <v-icon class="mr-4">
              mdi-palette
            </v-icon>
            Promote Product
          </v-btn>
        </v-list-item> -->
        <v-list-item>
          <v-btn class="d-flex justify-start" width="100%" outlined color="primary" to="/admin/business/marketing/email">
            <v-icon class="mr-4">
              mdi-email
            </v-icon>
            Email
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="d-flex justify-start" width="100%" outlined color="primary" to="/admin/business/marketing/brochures">
            <v-icon class="mr-4">
              mdi-file-document-outline
            </v-icon>
            Giclee Brochure
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-container fluid style="height:100%">
      <router-view />
    </v-container>
  </v-main>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  async created () {
    this.$store.dispatch('contacts/loadContacts')
  },
  methods: {
  }}
</script>

