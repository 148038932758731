import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/admin/artist`

const loadArtists = () => {
  return axios.get(api, {})
}
const loadArtist = (artistCode) => {
  return axios.get(api + '/' + artistCode )
}
const updateArtist = (artist) => {
  return axios.put(api + '/' + artist.artistCode, { ...artist })
}
const createArtist = (artist) => {
  return axios.post(api, { ...artist })
}
const loadArtistsForExport = () => {
  return axios.get(api + '/export', {})
}
const loadAllArtists = () => {
  return axios.get(api + '/all', {})
}
const loadArtistGiclees = (artistCodes) => {
  return axios.post(api + '/giclees', { ...artistCodes })
}

export default {
  loadArtists,
  loadArtist,
  createArtist,
  updateArtist,
  loadArtistsForExport,
  loadAllArtists,
  loadArtistGiclees
}
