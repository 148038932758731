import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/admin/order`

const loadOrders = () => {
  return axios.get(api, {})
}
const loadOrder = (id) => {
  return axios.get(api + '/' + id )
}
const updateOrder = (order) => {
  return axios.put(api + '/' + order.id, { ...order })
}
const createOrder = (cart) => {
  return axios.post(api, cart)
}

export default {
  loadOrders,
  loadOrder,
  createOrder,
  updateOrder,
}
