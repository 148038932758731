import axios from 'axios'
import S3Api from '@/api/s3'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/artwork`

const loadArtworks = () => {
  return axios.get(api)
}

const loadArtistArtworks = () => {
  return axios.get(api + '/artist')
}

const saveArtwork = (artwork) => {
  return axios.post(api, { ...artwork })
}

const updateArtwork = (artwork) => {
  return axios.put(api, { ...artwork })
}

const getArtworkProducts = (artworkCode) => {
  return axios.get(api + '/products/' + artworkCode )
}

const nextArtworkSequence = (artistCode) => {
  return axios.get(api + '/nextArtworkSequence/' + artistCode )
}

const uploadArtworkImage = (data) => {
  S3Api.getS3PresignedPost(`${data.path}/${data.name}`).then(function (postData) {
    const formData = new FormData()
    formData.append("file", data.file, data.name)
    axios.put(
      postData.data,
      data.file,
      {
        headers: {
          'Content-Type': 'image/jpeg'
        }
      }
    )
  })
}

export default {
  loadArtworks,
  loadArtistArtworks,
  saveArtwork,
  updateArtwork,
  nextArtworkSequence,
  uploadArtworkImage,
  getArtworkProducts
}
