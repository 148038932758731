<template>
  <v-app :style="{backgroundColor: backgroundColor}">
    <v-app-bar v-if="isPortalPage" style="margin-left:auto; margin-right: auto;" :style="{zIndex: 10}" app color="white" clipped-left >
      <template v-slot:img>
        <img class="mt-0" src="@/assets/doricmor_logo_2.jpg" :height="vp.mdAndUp ? 112 : 70" contain />
      </template>
      <v-spacer></v-spacer>
      <div>
        <v-btn icon small class="my-0 py=0">
          <v-icon color="primary">mdi-email</v-icon>
        </v-btn>
          <span  style="font-size: 0.9em"><a href="mailto:sales@doricmor.com">sales@doricmor.com</a></span><br>
        <v-btn icon small  class="my-0 py=0">
          <v-icon color="primary">mdi-phone</v-icon>
        </v-btn>
          <span style="font-size: 0.9em">+44 (0) 131 553 2772</span>
      </div>
      <template v-slot:extension>
        <v-tabs align-with-title show-arrows >
          <v-spacer></v-spacer>
          <v-tab exact to="/home">Home</v-tab>
          <template v-if="!isLoggedIn">
            <!-- <v-tab to="/products">Products</v-tab> -->
            <v-tab to="/artists">Artists</v-tab>
            <v-tab to="/retailers">Where to Buy</v-tab>
          </template>
          <template v-if="isCustomer">
            <v-tab to="/catalog">Products</v-tab>
            <!-- <v-menu bottom offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-tab to="/catalog" v-bind="attrs" v-on="on">Products
                  <v-icon>mdi-chevron-down</v-icon>
                </v-tab>
              </template>
              <v-list class="pt-0">
                <v-list-item exact to="/catalog/fineart">
                  <v-list-item-title>Fine Art</v-list-item-title>
                </v-list-item>
                <v-list-item exact to="/catalog/gifts">
                  <v-list-item-title>Giftware and Household</v-list-item-title>
                </v-list-item>
                <v-list-item exact to="/catalog/statioery">
                  <v-list-item-title>Stationery</v-list-item-title>
                </v-list-item>
                <v-list-item exact to="/catalog">
                  <v-list-item-title>Browse All</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </template>
          <!-- <template v-if="isPublishedArtist || isCustomArtist">
            <v-tab to="/user">Account</v-tab>
          </template> -->
          <template v-if="isCustomer">
            <v-tab exact to="/customer">Orders</v-tab>
          </template>
          <template v-if="isAdmin">
            <v-tab exact to="/catalog">Products</v-tab>
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">Sales &amp; Marketing
                  <v-icon>mdi-chevron-down</v-icon>
                </v-tab>
              </template>
              <v-list class="pt-0">
                <v-list-item to="/admin/business/orders">
                  <v-list-item-title>Orders</v-list-item-title>
                </v-list-item>
                <v-list-item to="/admin/business/customers">
                  <v-list-item-title>Customers</v-list-item-title>
                </v-list-item>
                <v-list-item to="/admin/business/reports">
                  <v-list-item-title>Reports</v-list-item-title>
                </v-list-item>
                <v-list-item to="/admin/business/marketing">
                  <v-list-item-title>Email & Marketing</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tab to="/admin/data">Data</v-tab>
            <v-tab v-if="hasXeroAccess" to="/admin/xero">Xero</v-tab>
          </template>
          <v-tab v-if="!isLoggedIn" to="/register">Register</v-tab>
          <v-tab v-if="!isLoggedIn" to="/login">Login</v-tab>
          <v-tab v-if="isCustomer" class="v-tab--active" to="/customer"><v-icon class="mr-2">mdi-cart-outline</v-icon> Cart({{totalCartItems}})</v-tab>
          <v-tab v-if="isLoggedIn" @click="logout">Logout</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <!-- <v-snackbar v-if="isLoggedIn" v-model="showLoginSnackbar" color="success" centered timeout="-1">
      <div class="my-10 mx-10">
      <h2 class="mb-4">Christmas and New Year Holidays</h2>
      <p>We will be closed from Friday, December 20th at 3:30pm until Monday, January 6th at 7.30am. Any orders placed during this time will be processed and dispatched upon our return. In the meantime enjoy the festivities.</p>
      <p>All the team at Doricmor</p>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="dismissLoginSnackbar()">
          Close
        </v-btn>
      </template>
    </v-snackbar> -->
    <!-- <countdown-timer :endTime="sessionExpiration" :startTime="now" v-if="isLoggedIn" v-model="openTimer" @logout="closeTimer"></countdown-timer> -->
    <router-view style="" />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import CountdownTimer from '@/components/CountdownTimer'
import LandingPage from '@/views/Public/Landing'
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Products created from the best original Scottish artwork",
    // all titles will be injected into this template
    // titleTemplate: "Doricmor | %s ",
  },
  data () {
    return {
      vp: this.$vuetify.breakpoint,
      route: this.$route,
      openDialog: true,
      showLogin: false,
      snackbar: true,
      drawer: false,
      now: Date.now()
    }
  },
  components: {
    LandingPage,
    CountdownTimer
  },
  async created () {
    this.$router.onReady( () => {
      this.route = this.$route.path
    }
    )
    this.$store.dispatch('retailers/loadRetailers')
  },
  mounted () {
    this.updateNow()
    this.interval = setInterval(this.updateNow.bind(this), 1000)
  },
  computed: {
      backgroundColor () {
        return this.$route.meta.landingPage ? '#1e1e1e' : 'white'
      },
      isPortalPage () {
      return this.$route.meta.landingPage ? false : true
    },
    openTimer () {
      return this.sessionExpiration - this.now < 120000 && this.sessionExpiration - this.now > -10
    },
    ...mapGetters([
      'isAdmin',
      'returningVisitor',
      'isCustomer',
      'isPublishedArtist',
      'isCustomArtist',
      'isLoggedIn',
      'hasXeroAccess',
      'showLoginSnackbar'
      ]),
    ...mapGetters('auth', ['sessionExpiration']),
    ...mapGetters('cart', ['totalCartItems'])
  },
  methods: {
    updateNow () {
      this.now = Date.now()
    },
    closeTimer () {
      this.logout()
    },
    dismissLoginSnackbar () {
      this.$store.dispatch('dismissLoginSnackbar')
    },
    logout () {
      this.$store.dispatch('logout')
      this.$store.dispatch('auth/clearToken')
      this.$router.push('/home').catch(err => { return err !== null })
    }
  }
}
</script>

<style lang="scss">
.v-clickable {
  cursor: pointer
}
</style>
<style scoped>
.app-bar {
  background: url('./assets/product_hero.jpg');
  background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.app-bar::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.25);
}
</style>
