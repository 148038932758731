<template>
  <v-container fluid>
    <div class="d-flex mt-4" v-if="filteredProducts">
      <v-autocomplete
        :disabled="selectedProducts.length > 0"
        class="mr-2"
        v-model="selectedArtist"
        :items="artists"
        outlined
        clearable
        clear-icon="mdi-close-box"
        dense
        :label="'Artist'"
      ></v-autocomplete>
      <v-autocomplete
        :disabled="selectedProducts.length > 0"
        class="mr-2"
        v-model="selectedProductType"
        :items="productTypes"
        outlined
        clearable
        clear-icon="mdi-close-box"
        dense
        label="Product"
      ></v-autocomplete>
      <v-autocomplete
        :disabled="selectedProducts.length > 0"
        class="mr-2"
        v-model="selectedStatus"
        :items="statuses"
        outlined
        clearable
        clear-icon="mdi-close-box"
        dense
        label="Status"
      ></v-autocomplete>
      <v-text-field
        :disabled="selectedProducts.length > 0"
        class="mr-2"
        v-model="searchText"
        label="Search"
        dense
        outlined
        clearable
      ></v-text-field>
      <v-btn color="success" @click.native="clearFilters()"> Reset </v-btn>
    </div>
    <div v-if="selectedStatus" class="d-flex align-center mb-4">
      <v-btn
        :disabled="selectedProducts.length === 0 || filter.status === 'READY'"
        color="success"
        class="ml-2"
        @click.native="setSelectedStatus('READY')"
      >
        ready
      </v-btn>
      <v-btn
        :disabled="selectedProducts.length === 0 || filter.status === 'ARCHIVED'"
        color="success"
        class="ml-2"
        @click.native="setSelectedStatus('ARCHIVED')"
      >
        archived
      </v-btn>
      <v-btn
        :disabled="selectedProducts.length === 0 || filter.status === 'PENDING'"
        color="success"
        class="ml-2"
        @click.native="setSelectedStatus('PENDING')"
      >
        pending
      </v-btn>
      <h4 class="ml-2">Change status of selected products</h4>
    </div>
    <v-data-table
      v-if="filteredProducts"
      v-model="selectedProducts"
      @click.row="editProduct()"
      :search="searchText"
      :items="filteredProducts"
      :loading="loadingData"
      :headers="headers"
      :show-select="selectedStatus"
      :footer-props="{
        itemsPerPageOptions: [50, 100, 250],
      }"
      dense
    >
      <template v-slot:[`item.productImage`]="{ item }">
        <v-img
          @click.native="editProduct(item)"
          class="my-2"
          max-height="150px"
          max-width="150px"
          :src="`${imageUrl}/300px/${item.productImage || item.artworkImage}`"
        ></v-img>
      </template>
      <template v-slot:[`item.sku`]="{ item }" @click.native="editProduct(item)">
        <span style="white-space: nowrap">{{ item.sku }}</span>
      </template>
      <template v-slot:[`item.artist`]="{ item }">
        {{ item.artist }}
      </template>
      <template v-slot:[`item.title`]="{ item }">
        {{ item.title }}
      </template>
      <template v-slot:[`item.manageStatus`]="{ item }">
        <div class="d-flex justify-end">
          <v-menu bottom left open-on-hover :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" outlined icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :disabled="item.status==='READY'" @click=" setStatus({ product: item, status: 'READY' })">
                <v-list-item-title>Mark Ready</v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="item.status==='PENDING'" @click=" setStatus({ product: item, status: 'PENDING' })">
                <v-list-item-title>Mark Pending</v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="item.status==='ARCHIVED'" @click=" setStatus({ product: item, status: 'ARCHIVED' })">
                <v-list-item-title>Mark Archived</v-list-item-title>
              </v-list-item>
              <v-list-item @click="editProduct(item)">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:[`item.barCode`]="{ item }">
        <vue-barcode
          style="display: none"
          class="v-clickable"
          ref="barcode"
          :value="item.barCode"
          format="EAN13"
          height="30"
        />
        {{ item.barCode }}
        <v-btn icon color="success" @click.native="barcodeDownload(item)">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <confirm-dialog ref="confirmDialog" />
    <product-dialog
      v-if="openDialog"
      v-model="openDialog"
      :variant="currentProduct.variant"
      :sku="currentProduct.sku || null"
      @close="closeDialog"
    />
  </v-container>
</template>

<script>
import ProductDialog from "./components/ProductDialog";
import ProductApi from "@/api/admin/product";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { svgToImgDownload } from "@/utilities/imageUtilities";
import VueBarcode from "@/components/vue-barcode";

export default {
  components: {
    ProductDialog,
    VueBarcode,
    ConfirmDialog,
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      searchText: null,
      selectedArtist: null,
      selectedProductType: null,
      selectedStatus: null,
      products: [],
      openDialog: false,
      currentProduct: null,
      selectedProducts: [],
      headers: [
        { text: "", value: "productImage" },
        { text: "Product", value: "variant" },
        { text: "Sku", value: "sku" },
        { text: "Artist", value: "artist" },
        { text: "Title", value: "title" },
        { text: "Barcode", value: "barCode" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "manageStatus", align: "center", sortable: false }
      ],
      selectedFile: null,
      validData: false,
      valid: true,
      processing: false,
      progress: 0,
      loadingData: true,
      mode: "preview",
    };
  },
  computed: {
    filter() {
      const obj = {};
      this.selectedArtist && (obj.artist = this.selectedArtist);
      this.selectedProductType && (obj.category = this.selectedProductType);
      this.selectedStatus && (obj.status = this.selectedStatus);
      return obj;
    },
    filteredProducts() {
      return _.filter(this.products, this.filter);
    },
    artists() {
      return _.uniq(this.filteredProducts.map((a) => a.artist));
    },
    productTypes() {
      return _.uniq(this.filteredProducts.map((a) => a.category).sort());
    },
    statuses() {
      return _.uniq(this.filteredProducts.map((a) => a.status));
    },
  },
  async created() {
    ProductApi.loadAllProducts()
      .then((results) => {
        this.products = results.data;
        this.loadingData = false;
      })
      .catch((err) => {
        console.log("err", err);
        this.loadingData = false;
      });
  },
  mounted: function () {},
  watch: {},
  methods: {
    clearFilters() {
      this.selectedArtist = null;
      this.selectedProductType = null;
      this.selectedStatus = null;
      this.searchText = null;
      this.selectedProducts = [];
    },
    barcodeDownload(item) {
      const svg = document.querySelector(`.vue-barcode-${item.barCode}`);
      svgToImgDownload(svg, `${item.sku}-BARCODE.jpg`, 500, 200);
    },
    setStatus({ product, status }) {
      console.log('click')
      product.status = status;
      ProductApi.setProductStatus(product)
        .then(async (results) => {
          this.loadingData = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.loadingData = false;
        });
    },
    async setSelectedStatus(status) {
      this.products = [];
      const skus = this.selectedProducts.map((product) => product.sku);
      let str = "<ul>";
      this.selectedProducts.forEach(function (slide) {
        str += `<li>${slide.sku} | ${slide.title}'</li>`;
      });
      str += "</ul>";
      if (
        await this.$refs.confirmDialog.open(
          `<p>Update the status of all these products?</p>`,
          `<p>Are you sure? This will update the following ${skus.length} products to "${status}".</p> ${str}`
        )
      ) {
        this.loadingData = true;
        ProductApi.setSelectedStatus(skus, status)
          .then(async (results) => {
            ProductApi.loadAllProducts()
              .then((results) => {
                this.products = results.data;
                this.loadingData = false;
                this.selectedProducts = [];
              })
              .catch((err) => {
                console.log("err", err);
              });
          })
          .catch((err) => {
            console.log("err", err);
            this.loadingData = false;
          });
      } else {
        this.loadingData = false;
      }
    },
    async saveProduct(product) {
      ProductApi.saveProduct(product)
        .then(async (results) => {
          this.loadingData = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.loadingData = false;
        });
    },
    editProduct(evt) {
      console.log('clicked', evt)
      this.currentProduct = { ...evt };
      this.openDialog = true;
    },
    closeDialog(element) {
      this.loadingData = true;
      this.currentProduct = null;
      this.openDialog = false;
      ProductApi.loadAllProducts()
        .then((results) => {
          this.products = results.data;
          this.loadingData = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.loadingData = false;
        });
    },
  },
};
</script>
