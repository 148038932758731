import { lazyLoad } from '../../index'

export default {
  path: 'orders',
  component: lazyLoad('Admin/Business/Order/layout'),
  redirect: 'orders/customer',
  children: [
    {
      path: 'picks',
      component: lazyLoad('Admin/Business/Order/Picks/layout'),
    },
    {
      path: 'customer',
      component: lazyLoad('Admin/Business/Order/Customer/layout'),
    },
    {
      path: 'internal',
      component: lazyLoad('Admin/Business/Order/Internal/layout'),
    },
  ]
}
