<template>
  <div>
    <h1 class="display-1 font-weight-bold mx-1 mb-3">
      Sync Products With Xero.
    </h1>
    <p>
      This page shows Products in READY status
    </p>
    <div class="d-flex mt-4" v-if="filteredProducts">
      <v-autocomplete
        :disabled="selectedProducts.length > 0"
        class="mr-2"
        v-model="selectedArtist"
        :items="artists"
        outlined
        clearable
        clear-icon="mdi-close-box"
        dense
        :label="'Artist'"
      ></v-autocomplete>
      <v-autocomplete
        :disabled="selectedProducts.length > 0"
        class="mr-2"
        v-model="selectedProductType"
        :items="productTypes"
        outlined
        clearable
        clear-icon="mdi-close-box"
        dense
        label="Product"
      ></v-autocomplete>
      <v-text-field
        :disabled="selectedProducts.length > 0"
        class="mr-2"
        v-model="searchText"
        label="Search"
        dense
        outlined
        clearable
      ></v-text-field>
      <v-btn color="success" @click.native="clearFilters()"> Reset </v-btn>
    </div>
    <div class="d-flex align-center mb-4">
      <v-btn
        :disabled="selectedProducts.length === 0"
        color="success"
        class="ml-2"
        @click.native="uploadProducts()"
      >
        set active
      </v-btn>
      <h4 class="ml-4">This will set products to ACTIVE and sync them woith Xero items</h4>
    </div>
    <v-data-table
      v-if="filteredProducts"
      v-model="selectedProducts"
      @click.row="editProduct()"
      :search="searchText"
      :items="filteredProducts"
      :loading="loadingData"
      :headers="headers"
      show-select
      :footer-props="{
        itemsPerPageOptions: [50, 100, 250],
      }"
      dense
    >
      <template v-slot:[`item.productImage`]="{ item }">
        <v-img
          class="my-2"
          max-height="150px"
          max-width="150px"
          :src="`${imageUrl}/300px/${item.productImage || item.artworkImage}`"
        ></v-img>
      </template>
      <template v-slot:[`item.sku`]="{ item }" @click.native="editProduct(item)">
        <span style="white-space: nowrap">{{ item.sku }}</span>
      </template>
      <template v-slot:[`item.artist`]="{ item }">
        {{ item.artist }}
      </template>
      <template v-slot:[`item.title`]="{ item }">
        {{ item.title }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ProductApi from '@/api/admin/product'
import AccountingApi from '@/api/accounting'

export default {
  props: {
  },
  components: {
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      searchText: null,
      selectedArtist: null,
      selectedProductType: null,
      selectedStatus: null,
      products: [],
      openDialog: false,
      currentProduct: null,
      selectedProducts: [],
      headers: [
        { text: "", value: "productImage" },
        { text: "Product", value: "variant" },
        { text: "Sku", value: "sku" },
        { text: "Artist", value: "artist" },
        { text: "Title", value: "title" },
        { text: "Barcode", value: "barCode" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "manageStatus", align: "center", sortable: false }
      ],
      selectedFile: null,
      validData: false,
      valid: true,
      processing: false,
      progress: 0,
      loadingData: true,
      mode: "preview",
    };
  },
  computed: {
    filter() {
      const obj = {};
      this.selectedArtist && (obj.artist = this.selectedArtist);
      this.selectedProductType && (obj.category = this.selectedProductType);
      this.selectedStatus && (obj.status = this.selectedStatus);
      return obj;
    },
    filteredProducts() {
      return _.filter(this.products, this.filter);
    },
    artists() {
      return _.uniq(this.filteredProducts.map((a) => a.artist));
    },
    productTypes() {
      return _.uniq(this.filteredProducts.map((a) => a.category).sort());
    },
    statuses() {
      return _.uniq(this.filteredProducts.map((a) => a.status));
    },
  },
  async created () {
    await ProductApi.loadAllProducts({filter: {status: 'READY'}}).then(response => {
      this.products = response.data
      this.loadingData = false
    }).catch(err => console.log('woops', err))
  },
  methods: {
    clearFilters() {
      this.selectedArtist = null;
      this.selectedProductType = null;
      this.searchText = null;
      this.selectedProducts = [];
    },
    async uploadProducts () {
      this.loadingData = true
      const skus = this.selectedProducts.map((product) => product.sku);
      await ProductApi.setSelectedStatus(skus, 'ACTIVE')
      await AccountingApi.updateXeroItems(this.selectedProducts).then(response => {
        ProductApi.loadAllProducts({filter: {status: 'READY'}}).then(response => {
          this.products = response.data
          this.loadingData = false
        }).catch(err => console.log('woops', err))
      }).catch(err => console.log('woops', err))
    },
  }
}
</script>
