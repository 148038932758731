<template>
<div>
  <v-row>
    <v-col class="mb-4">
      <h1 class="display-1 font-weight-bold mx-1 mb-3">Current Product Picks</h1>
      <!-- <pre>{{ cart }}</pre> -->
        <v-data-table
          id="testHtml"
          :items="cart.items"
          item-key="orderNumber"
          :headers="headers"
          hide-default-footer
          :disable-pagination=true
          no-data-text="No products"
        >
          <template v-slot:[`item.img`]="{ item }">
            <v-img class="mx-2" max-height="50px" max-width="50px"
                :src="`${imageUrl}/100px/${getImageToDisplay(item.product.productImage)}`"
              ></v-img>
          </template>
          <template v-slot:[`item.artwork.title`]="{ item }">
              {{item.artwork.title}} by {{item.artist.name}}
          </template>
          <template v-slot:[`item.product`]="{ item }">
              {{item.productTypeVariant.title}}</br>
              {{
                item.size ||
                item.productTypeVariant.greetingCardSize ||
                item.productTypeVariant.mountedPrintSize ||
                item.productTypeVariant.magnetSize ||
                ''}}
          </template>
          <template v-slot:[`item.product.unitPrice`]="{ item }">
              £{{item.unitPrice.toFixed(2)}}
          </template>
          <template v-slot:[`item.unitDiscount`]="{ item }">
              {{item.unitDiscount && item.unitDiscount + '%'}}
          </template>
          <template v-slot:[`item.qty`]="{ item }">
            <div class="d-flex align-center mr-2">
              <v-icon small @click.native="decrement(item)">
                mdi-chevron-left
              </v-icon>
              <span class="mx-2">{{ item.qty }}</span>
              <v-icon small @click.native="increment(item)">
                mdi-chevron-right
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.total`]="{ item }">
              £{{(item.unitDiscount ? item.unitPrice * item.qty * (100-item.unitDiscount)/100 : item.unitPrice * item.qty ).toFixed(2)}}
          </template>
          <template v-slot:[`item.remove`]="{ item }">
            <v-icon @click.native="remove(item)">
              mdi-delete
            </v-icon>
          </template>
          <template slot="body.append">
            <tr>
              <th style="font-size: .875rem; text-align: left">Total (Net)</th>
              <th colspan="5"></th>
              <th style="font-size: .875rem; text-align: right">£{{ total }}</th>
              <th />
            </tr>
          </template>
        </v-data-table>
        <confirm-dialog ref="confirmDialog" />
        <v-spacer></v-spacer>
        <p>To create an order with these products, first select a contact</p>
        <v-autocomplete
          v-model="selectedContact"
          :items="contacts"
          :return-object=true
          outlined
          clearable
          clear-icon="mdi-close-box"
          dense
          item-text="name"
          label="Contact"
        >
          <!-- <template v-slot:item="data">
            <template>
              <v-list-item-content v-text="`${data.item.customer} (${data.item.count})`"></v-list-item-content>
            </template>
          </template> -->
        </v-autocomplete>
        <xero-contact-card v-if="selectedContact" :contact="selectedContact"></xero-contact-card>
        <v-col class="d-flex ml-auto" cols="12">
          <v-btn
            :disabled="isDirty || !cart.items.length"
            color="success"
            class="mr-4"
            @click.native="generatePdf()"
            >
            PDF
            <v-icon>
              mdi-download
            </v-icon>
          </v-btn>
          <v-btn
            :disabled="isDirty || !cart.items.length || !validMugs || !selectedContact"
            color="success"
            class="mr-4"
            @click="submit"
            >Create Order</v-btn
          >
          <v-btn
            :disabled="!isDirty"
            color="success"
            class="mr-4"
            @click="update"
            >Save Changes</v-btn
          >
          <v-btn
            :disabled="!isDirty || !cart.items.length"
            color="primary"
            @click="reset"
            >Reset</v-btn
          >
        </v-col>
      <v-alert v-if="!validMugs"
        class="mt-2"
        width="50%"
        border="top"
        colored-border
        type="error"
        elevation="2"
      >
        <p>For courier deliveries mugs must be ordered in multiples of 36. This can be any mixture of designs, with each design in multiples of 3</p>
        <p>Currently your cart contains {{totalMugs}} mugs</p>
      </v-alert>
    </v-col>
  </v-row>
  <v-row>
    <v-col lg="6" sm="12">
      <v-card flat outlined>
        <v-card-title>Delivery</v-card-title>
        <v-card-text class="pb-0">
          <v-row>
            <v-col sm=4>
              <v-select
                outlined
                v-model="cart.delivery"
                :items="deliveryOptions"
                @change="updateDelivery"
                label="Delivery Type">
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="cart.delivery === 'COURIER'" class="py-0 mt-0">
          <ul>
            <li><strong>UK Mainland Deliveries</strong> £8.00 + VAT (up to 20Kg)</li>
            <li><strong>Islands</strong> £22.30 + VAT (up to 20Kg)</li>
          </ul>
          <p class="my-2">In our experience, approximately 90% of all customer orders are under 20kg.</p>
          <p class="mt-2">If your order is above 20kg, your basic shipping cost outlined above will be subject to £0.37 additional per kg surcharge.</p>
        </v-card-text>
        <v-card-text v-if="cart.delivery === 'COLLECT'" class="pb-0">
          <p>We will contact you when your order is ready to be collected.</p>
          <p>Our opening hours are 7.30am - 3.30pm. If you need to collect outwith these hours please get in touch with us on 0131 553 2772 or email us at sales@doricmor.com</p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { mapGetters } from 'vuex'
import CartApi from '@/api/customer/cart'
import AccountingApi from '@/api/accounting'
import DiscountApi from '@/api/customer/discount'
import XeroContactCard from '../../../../../components/XeroContactCard.vue'
export default {
  components: {
    ConfirmDialog,
    XeroContactCard
  },
  data () {
    return {
      loadingData: true,
      imageUrl: process.env.VUE_APP_IMG_URL,
      originalOrder: {},
      originalCart: {},
      isDirty: false,
      selectedContact: null,
      contacts: [],
      promoCode: null,
      promoInfo: {
        show: false,
        type: "success",
        message: "Discounts applied!"
      },
      deliveryOptions: [
        { text: 'Courier', value: 'COURIER' },
        { text: 'Will collect', value: 'COLLECT' }
      ],
      headers: [
        { text: '', value: 'img' },
        {
          text: 'Title',
          align: 'start',
          value: 'artwork.title'
        },
        { text: 'Product', value: 'product' },
        { text: 'Unit Price', value: 'product.unitPrice', align: 'end' },
        { text: 'Quantity', value: 'qty' },
        { text: 'Discount', value: 'unitDiscount', align: 'end' },
        { text: 'Total', value: 'total', align: 'end' },
        { text: '', value: 'remove' }
      ]
    }
  },
  methods: {
    reset () {
      this.$store.dispatch('cart/updateCart', this.originalCart)
      this.isDirty = false
    },
    async applyPromotion () {
      DiscountApi.getDiscount(this.promoCode).then(async (promo) => {
        console.log('promo from db', promo)
        await this.$store.dispatch('cart/applyPromotion', promo.data.discount)
        await CartApi.updateCart(this.$store.getters['cart/cart'])
        this.promoInfo.show = true
        this.promoInfo.type = "success"
        this.promoInfo.message = `Discounts applied`
      })
      .catch(async (err) => {
        console.log('err', err.response?.data, err)
        await this.$store.dispatch('cart/clearPromotion')
        this.promoInfo.show = true
        this.promoInfo.type = "error"
        this.promoInfo.message = `Promo code ${this.promoCode} is invalid`
        this.promoCode = null
      }
      )
      this.isDirty = false
    },
    async update () {
      await this.$store.dispatch('cart/updateCart', this.cart)
      this.originalCart = JSON.parse(JSON.stringify(this.cart))
      await CartApi.updateCart(this.$store.getters['cart/cart'])
      this.isDirty = false
    },
    async submit () {
      if (
        await this.$refs.confirmDialog.open(
          'Create an order for this customer?',
          'Once submitted you won\'t be able to modify the order.'
        )
      ) {
        this.cart.accountNumber = this.selectedContact.accountNumber
        this.$store.dispatch('cart/submitPicks', this.cart)
      }
    },
    updateDelivery () {
      this.$store.dispatch('cart/updateCart', this.cart)
      this.isDirty = true
    },
    increment (item) {
      item.qty += item.productType.multQty
      this.$store.dispatch('cart/updateCart', this.cart)
      this.isDirty = true
    },
    decrement (item) {
      if (item.qty > item.productType.minQty) {
        item.qty -= item.productType.multQty
        this.$store.dispatch('cart/updateCart', this.cart)
        this.isDirty = true
      }
    },
    async remove (item) {
      if (
        await this.$refs.confirmDialog.open(
          'Confirm',
          'Are you sure you want to delete this item?'
        )
      ) {
        this.cart.items.splice(this.cart.items.findIndex(cartItem => item.sku === cartItem.sku), 1)
        this.$store.dispatch('cart/updateCart', this.cart)
        this.isDirty = true
      }
    },
    async generatePdf () {
      CartApi.getCartPDF()
    },
  },
  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      validMugs: 'cart/validMugs',
      totalMugs: 'cart/totalMugs',
      total: 'cart/total',
      user: 'user'
    }),
  },
  async created () {
    const resp = await CartApi.getCart()
    await AccountingApi.getContacts().then(response => {
      this.contacts = response.data.filter(item => item.accountNumber !== null)
    })
    this.$store.dispatch('cart/updateCart', resp.data)
    this.originalCart = JSON.parse(JSON.stringify(this.cart))
    this.promoCode = this.cart.promo ? this.cart.promo.code : null
    this.loadingData = false
  },
  async beforeRouteLeave (to, from, next) {
    if (this.isDirty) {
      if (
        await this.$refs.confirmDialog.open(
          'Confirm',
          'Are you sure you want to leave this page?" You have unsaved changes'
        )
      ) {
        next()
      } else {
        next(false)
      }
    } else next()
  }
}
</script>
